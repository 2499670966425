
import Vue from "vue";
import DatePicker from "@/components/form-components/DatePicker.vue";
import store from "@/store";
import LiveSearch from "@/components/form-components/LiveSearch.vue";
import ProductsPicker from "../../ProductsPicker.vue";
import rules from "@/services/helpers/validation-rules";
import { mask } from "vue-the-mask";
import ClientData from "@/modules/orders/components/ClientData.vue";
import Documents from "@/modules/orders/components/Documents.vue";
import OrderServices from "@/modules/orders/components/OrderServices.vue";
import Client from "@/modules/orders/mixins/client";

export default Vue.extend({
  name: "AtaCreateOrder",

  components: {
    OrderServices,
    ClientData,
    ProductsPicker,
    LiveSearch,
    Documents,
    DatePicker
  },

  props: {
    language: {
      type: String,
      default: "ru",
      required: true
    },
    serviceId: {
      required: true,
      type: Number
    },
    allocations: {
      required: true,
      type: Object
    }
  },

  directives: { mask },

  mixins: [Client],

  data: () => ({
    rules,
    isCreate: true as boolean,
    loading: false as boolean,
    isSelectBeneficiary: false as boolean,
    selectedCard: null as null | SelectComponentInterface,
    model: {
      documents: [] as Array<any>,
      details: {
        goods: [] as Array<any>,
        services: [] as Array<any>,
        countries: [] as Array<any>,
        exchange_rate: "1.0000",
        outputs: "1"
      } as any,
      order: {
        source: "operator",
        document_type: "original",
        client: { type: "" } as any,
        document_valid_from: new Date().toISOString().substr(0, 10),
        guaranty_required: false as boolean,
        power_of_attorney: "beneficiary" as string
      } as any
    } as any,
    scannedDocuments: [] as Array<any>,
    errorMessages: {} as any,
    selects: {
      cardTypes: [] as Array<any>,
      managers: [] as Array<any>,
      countries: [] as Array<any>,
      purpose: [] as Array<any>,
      packingCategories: [] as Array<any>,
      transports: [] as Array<any>,
      currencies: [] as Array<any>,
      units: [] as Array<any>,
      languages: [] as Array<any>,
      client_types: [] as Array<any>,
      release_types: [] as Array<any>,
      services: [] as Array<any>
    } as any,
    lang: store.getters["localization/getCurrent"],
    allDelegates: false as boolean,
    currency: {} as any,
    selectedTab: 0,
    totalSum: 0 as number,
    isShowCountries: {
      destination: false as boolean,
      transit: false as boolean
    } as any,
    isShowDocuments: true as boolean,
    isShowDocumentsNumber: false as boolean,
    isLoading: false,
    breadcrumb: [
      {
        text: "orders.title",
        disabled: false,
        href: "/orders"
      },
      {
        text: "orders.form.header.create",
        disabled: true,
        href: `/orders/edit`
      }
    ],
    defaultCountry: [] as Array<number>,
    powerOfAttorney: null as any,
    serviceKey: 0 as number
  }),

  watch: {
    language: {
      immediate: true,
      handler() {
        this.model.order.language = this.language;
      }
    },
    goodsSum() {
      if (this.goodsSum) {
        this.model.details.required_guaranty_sum = Math.max(
          Math.trunc(0.3 * this.goodsSum),
          0
        );
      }
    },
    "model.documents": {
      immediate: true,
      deep: true,
      handler() {
        this.$forceUpdate();
      }
    }
  },

  computed: {
    isClassic() {
      return this.model.order.power_of_attorney === "classic";
    },
    destinationCountries() {
      return this.model.details.countries.filter(
        (item: any) => item.scope == "destination"
      );
    },
    transitedCountries() {
      return this.model.details.countries.filter(
        (item: any) => item.scope == "transit"
      );
    },
    formattedDate() {
      return this.$moment(this.model.order.document_valid_from, "YYYY-MM-DD")
        .add(1, "years")
        .subtract(1, "days")
        .format("DD.MM.YYYY");
    },
    goodsSum() {
      return this.model.details.goods.reduce(
        (
          total: number,
          { quantity, price }: { quantity: number; price: number }
        ) => {
          return total + Number(quantity) * Number(price);
        },
        0
      );
    },
    parentLanguage() {
      return this.model.order.language === "ro";
    },
    isAta() {
      return this.serviceId === 1;
    },
    isAtaSelected() {
      if (this.isAta) {
        return this.selects.cardTypes.slice(0, 3);
      }
      return this.selects.cardTypes;
    },
    correctManagers() {
      return this.selects.managers
        .filter((item: any) => {
          return item.value === 69 || item.value === 112;
        })
        .reverse();
    }
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    changePowerType(type: string) {
      this.model.order.power_of_attorney = type;
    },
    isJuridicalPerson(): boolean {
      return this.model.order?.client.type === "juridical";
    },
    isDefaultCountry(country_id: number) {
      return this.defaultCountry.includes(country_id);
    },
    async loadData() {
      this.loading = true;
      try {
        const headers = {
          "x-localization": this.language
        };
        const response = await Promise.all([
          this.$API.transportTypes().getList(headers),
          this.$API.purposeOfUse().getList(this.serviceId, headers),
          this.$API.countries().getAcceptAta(),
          this.$API.services().getListByServiceType(this.serviceId),
          this.$API.orders().getOrderReleaseModes(),
          this.$API.clients().getClientTypes(),
          this.$API.orders().getCardTypes(this.serviceId),
          this.$API.measureUnits().getList({ service_type_id: this.serviceId }),
          this.$API.packingCategories().getList(headers),
          this.$API.currency().getList(),
          this.$API.orders().getLanguages(),
          this.$API.branches().getList(),
          this.$API.employees().getList({
            filter: {
              subdivisions: this.model.order.subdivision_id
            }
          })
        ]);

        await this.setServerResponse(response);
      } catch (e) {
        await store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    isCheckedCountry(country: any, scope: string): boolean {
      return this.model.details.countries?.some(
        (item: any) => country.value === item.country_id && item.scope === scope
      );
    },
    async submit(): Promise<void> {
      this.isLoading = true;
      const form = this.$refs.form as Vue;
      try {
        if (form.validate()) {
          this.model.documents = this.model.documents.filter(
            (item: any) => item.file_type !== "confirm_represent_power"
          );

          if (this.powerOfAttorney.file) {
            this.model.documents.push(this.powerOfAttorney);
          }

          const model = { ...this.model };
          model.order.service_type_id = this.serviceId;
          const { branch_id, subdivision_id, handler_id } = this.allocations;
          model.order.branch_id = branch_id;
          model.order.subdivision_id = subdivision_id;
          model.order.handler_id = handler_id;

          if (this.parentLanguage) {
            for (const key in model.details.goods) {
              model.details.goods[key].name_ro = model.details.goods[key].name;
            }
          }

          if (!model.details.guaranty_required) {
            model.details.required_guaranty_sum = 0;
          }

          model.order.document_valid_until = this.$moment(
            this.model.order.document_valid_from,
            "YYYY-MM-DD"
          )
            .add(1, "years")
            .subtract(1, "days")
            .format("YYYY-MM-DD");

          await this.$API.orders().create(model);
          form.updateGuard();
          await this.$router.push("/orders");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        } else {
          this.selectedTab = 0;
          await setTimeout(() => {
            const firstErrorInput = form.inputs.find(
              (item: any) => item.messagesToDisplay.length
            );

            if (firstErrorInput) {
              this.$vuetify.goTo(firstErrorInput.$el, {
                offset: 150
              });
            }
          }, 500);
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          if (e.errors.length === 1) {
            if (
              e.errors.hasOwnProperty("order") &&
              e.errors.order.hasOwnProperty("required_guaranty_sum")
            ) {
              this.selectedTab = 1;
            }
          } else if (e.errors.length) {
            this.selectedTab = 0;
          }

          await setTimeout(() => {
            const firstErrorInput = form.inputs.find(
              (item: any) => item.messagesToDisplay.length
            );

            if (firstErrorInput) {
              this.$vuetify.goTo(firstErrorInput.$el, {
                offset: 150
              });
            }
          }, 500);

          setTimeout(() => {
            this.errorMessages = {};
          }, 4000);
        }
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.isLoading = false;
    },
    async setServerResponse(response: Array<any>) {
      const [
        transports,
        purpose,
        countries,
        services,
        releaseModes,
        clientTypes,
        cardTypes,
        units,
        packingCategories,
        currencies,
        languages,
        branches,
        managers
      ] = response;

      this.selects.currencies = currencies.items;
      this.selects.transports = transports.items;
      this.model.details.transport_category_id = transports.default;
      this.selects.languages = languages.items;
      this.selects.purpose = purpose;
      this.selects.countries = countries.items;

      if (countries.default) {
        this.defaultCountry = countries.default;
        countries.default.forEach((country_id: number) => {
          this.model.details.countries.push({
            country_id,
            scope: "destination"
          });
        });
      }

      this.selects.services = services;
      this.selects.managers = managers.items;
      this.model.details.employee_id = managers.default;
      this.selects.release_types = releaseModes.items;
      this.model.order.release_mode = releaseModes.default;
      this.selects.client_types = clientTypes;
      this.selects.cardTypes = cardTypes.items;
      this.model.order.document_type = cardTypes.default;
      this.selects.units = units;
      this.selects.packingCategories = packingCategories;
      this.selects.branches = branches;

      this.$nextTick(() => {
        this.currency = currencies.items.find(
          (item: any) => item.value === currencies.default
        );

        this.getExchangeRate(this.currency);
      });
    },
    addCountry(country: any, scope: string) {
      if (!this.isSelectBeneficiary || this.isDefaultCountry(country.value)) {
        return;
      }
      if (
        this.model.details.countries.some(
          (item: any) =>
            item.country_id === country.value && item.scope === scope
        )
      ) {
        const countryIndex = this.model.details.countries.findIndex(
          (item: any) =>
            item.country_id === country.value && item.scope === scope
        );
        if (countryIndex !== -1) {
          this.model.details.countries.splice(countryIndex, 1);
        }
      } else {
        this.model.details.countries.push({ country_id: country.value, scope });
      }
    },
    async getExchangeRate(item: any): Promise<void> {
      try {
        this.model.details.currency_id = item.value;
        this.currency = item;

        if (item.text === "MDL") {
          this.model.details.exchange_rate = "1.0000";
          return;
        }

        const response = await this.$API
          .exchangeRates()
          .getExchangeRate(item.value);

        if (response) {
          this.model.details.exchange_rate = response.value;
        }
      } catch (e) {
        this.model.details.exchange_rate = 1;
        await this.$store.dispatch(
          "alert/showError",
          this.$t("orders.form.fields.no_rate")
        );
      }
    },
    changeData(items: Array<any>) {
      this.model.details.goods = items;
    },
    setParentCard(card: any) {
      if (card?.value) {
        this.model.order.parent_document_id = card.value;
      }
    },
    getPayments(event: any) {
      this.model.payments = event;
    },
    toggleCountries(type: string): void {
      this.isShowCountries[type] = !this.isShowCountries[type];
    },
    setPurposeDescription(event: any) {
      this.model.details.purpose_id = event.value;
      if (event?.hasOwnProperty("description")) {
        this.model.details.purpose_description = event.description;
      }
    },
    getOrderSum(ev: any) {
      this.totalSum = ev;
    },
    updateVat(index: number): void {
      this.model.details.services[index].sum_no_vat = Number(
        this.model.details.services[index].sum_no_vat
      );
      this.model.details.services[index].sum_with_vat = this.addVat(
        Number(this.model.details.services[index].sum_no_vat)
      );
      this.model.details.services[index].remained_sum =
        Number(this.model.details.services[index].sum_with_vat) -
        Number(this.model.details.services[index].paid_sum);
    },
    removeService(service: any) {
      if (Array.isArray(service)) {
        for (const item of service) {
          const serviceIndex = this.model.details.services.findIndex(
            (el: any) => el.service_id === item
          );
          if (serviceIndex !== -1) {
            this.model.details.services.splice(serviceIndex, 1);
          }
        }
      } else {
        const serviceIndex = this.model.details.services.findIndex(
          (item: any) => item.service_id === service
        );
        if (serviceIndex !== -1) {
          this.model.details.services.splice(serviceIndex, 1);
        }
      }
    },
    addVat(sum: number): number {
      return sum + (sum * 20) / 100;
    },
    setService(service: any): void {
      if (
        !this.model.details?.services?.some(
          (item: any) => item.service_id === service.service_id
        )
      ) {
        this.model.details.services.push(service);
      } else {
        const serviceIndex = this.model.details.services.findIndex(
          (item: any) => item.service_id === service.service_id
        );
        this.model.details.services[serviceIndex] = {
          ...this.model.details.services[serviceIndex],
          ...service
        };
      }
    },
    loadDocuments(val: any) {
      this.model.documents = val;
    },
    setReleaseMode(event: any): void {
      this.model.order.release_mode = event;
      this.model.order = JSON.parse(JSON.stringify(this.model.order));
      this.$forceUpdate();
    }
  }
});
